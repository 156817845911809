import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from "axios"
import { number } from 'prop-types'
import HttpService from 'src/services/HttpService'
const {REACT_APP_TARGET_SRV} = process.env

export type IDataBranchFee= {
    //make similar with API list (recommended)
    branchId: string
    branchName: string
    receivedAmount: number
    totalFee: number
}

export type IDataTotalTransaction= {
   prevMonth:number
   prevYear:number
   selectedMonth:number
   selectedYear:number
   prevMonthKafalah:number
   prevMonthClosedTransaction:number
   selectedMonthKafalah:number
   selectedMonthClosedTransaction:number
   prevMonthClosedTransactionCustomerNumbers:number
   selectedMonthClosedTransactionCustomerNumbers:number
   prevMonthTotalRegisteredCustomers:number
   selectedMonthTotalRegisteredCustomers:number
}

export type IDataBranchIncome= {
    branchId: string
    branchName: string
    period: string
    // amount: number
    branchTransactionAmount: number;
    allTransactionAmount: number;
    contributionPercentage: string;
}

export type IDataCustomerStat={
    branchId: string
    branchName: string
    numOfReadyCustomers: number
    numOfProcessingCustomers: number
    totalCustomers: number
}

type uiType = 'LIST' | 'EDIT' | 'ADD'

interface IUI {
    mode: uiType
    isOpen: boolean
}

interface IStateData {
    loading: boolean
    list: IDataBranchFee[]
    listTotTranPrev: IDataTotalTransaction[]
    listTotTranSel: IDataTotalTransaction[]
    listBrIncPrev: IDataBranchIncome[]
    listBrIncSel: IDataBranchIncome[]
    listCusStat: IDataCustomerStat[]
    error: string
    ui: IUI
    selected?: IStateData

}

const initialState: IStateData= {
    loading: false,
    error: '',
    list : [],
    listTotTranPrev:[],
    listTotTranSel:[],
    listBrIncPrev:[],
    listBrIncSel:[],
    listCusStat:[],
    ui: {
        mode: 'LIST',
        isOpen: false
    }
}

export const fetchAllFees= createAsyncThunk('dashboard/allFees', ()=>{
    return axios
       .get(REACT_APP_TARGET_SRV + `/branches/fees`, {headers: {...HttpService.header()}})
       .then(response=>{

           const responseIn =  response.data.map((v,i)=>{
               return {
                   ...v,
               }
           })

           return responseIn
       })
})

export type IDataParam={
    bulan:string,
    tahun:string
}

export const fetchTransSum= createAsyncThunk('dashboard/transSum', async(param:IDataParam)=>{
    return await axios
       .get(REACT_APP_TARGET_SRV + `/dashboard/transactions/${param?.bulan}/${param?.tahun}`, {headers: {...HttpService.header()}})
       .then(response=>{
           return response.data
        })
})

export const fetchTransSumPrev= createAsyncThunk('dashboard/transSumPrev', async(param:IDataParam)=>{
    return await axios
       .get(REACT_APP_TARGET_SRV + `/dashboard/transactions/${Number(param?.bulan)-1}/${param?.tahun}`, {headers: {...HttpService.header()}})
       .then(response=>{
           return response.data
        })
})

export const fetchBranchIncome= createAsyncThunk('dashboard/Fetch Branch Income by Period',async(param:IDataParam)=>{
    return await axios
    //    .get(REACT_APP_TARGET_SRV + `/branches/fees/${param?.bulan}/${param?.tahun}?limit=5`, {headers: {...HttpService.header()}})
        .get(REACT_APP_TARGET_SRV + `/dashboard/top-5-performer-branch/${param?.bulan}/${param?.tahun}`, {headers: {...HttpService.header()}})
       .then(response=>{

           const responseIn =  response.data.map((v,i)=>{
               return {
                   ...v,
               }
           })

           return responseIn
       })
})

export const fetchBranchIncomePrev= createAsyncThunk('dashboard/Fetch Branch Income by Period Prev',async(param:IDataParam)=>{
    return await axios
    //    .get(REACT_APP_TARGET_SRV + `/branches/fees/${Number(param?.bulan)-1}/${param?.tahun}?limit=5`, {headers: {...HttpService.header()}})
        .get(REACT_APP_TARGET_SRV + `/dashboard/top-5-performer-branch/${Number(param?.bulan)-1}/${param?.tahun}`, {headers: {...HttpService.header()}})
       .then(response=>{

           const responseIn =  response.data.map((v,i)=>{
               return {
                   ...v,
               }
           })

           return responseIn
       })
})

export const fetchCustomerStat= createAsyncThunk('dashboard/customerStat', async(param:IDataParam)=>{
    return await axios
       .get(REACT_APP_TARGET_SRV + `/dashboard/customer-statistics/${param?.bulan}/${param?.tahun}`, {headers: {...HttpService.header()}})
       .then(response=>{
           return response.data
        })
})

export const dataSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setUserList: (state, action)=>{
            state.list=action.payload
        }
    },
    extraReducers: (builder) => {
        
        builder.addCase(fetchAllFees.pending, (state)=>{
            state.loading=true
            state.error=''
            state.list=[]
        })
        builder.addCase(fetchAllFees.fulfilled, (state, action: PayloadAction<IDataBranchFee[]>) => {
            state.loading=false
            state.list=action.payload
            state.error=''
        })
        builder.addCase(fetchAllFees.rejected, (state, action)=>{
            state.loading=false
            state.list=[]
            state.error=action.error.message || 'Something went wrong'
        })

        builder.addCase(fetchTransSum.pending, (state)=>{
            state.loading=true
            state.error=''
            state.listTotTranSel=[]
        })
        builder.addCase(fetchTransSum.fulfilled, (state, action: PayloadAction<IDataTotalTransaction[]>) => {
            state.loading=false
            state.listTotTranSel=action.payload
            state.error=''
        })
        builder.addCase(fetchTransSum.rejected, (state, action)=>{
            state.loading=false
            state.listTotTranSel=[]
            state.error=action.error.message || 'Something went wrong'
        })

        builder.addCase(fetchTransSumPrev.pending, (state)=>{
            state.loading=true
            state.error=''
            state.listTotTranPrev=[]
        })
        builder.addCase(fetchTransSumPrev.fulfilled, (state, action: PayloadAction<IDataTotalTransaction[]>) => {
            state.loading=false
            state.listTotTranPrev=action.payload
            state.error=''
        })
        builder.addCase(fetchTransSumPrev.rejected, (state, action)=>{
            state.loading=false
            state.listTotTranPrev=[]
            state.error=action.error.message || 'Something went wrong'
        })

        builder.addCase(fetchBranchIncome.pending, (state)=>{
            state.loading=true;
            state.error='';
            state.listBrIncSel=[]
        })
        builder.addCase(fetchBranchIncome.fulfilled, (state, action: PayloadAction<IDataBranchIncome[]>) => {
            state.loading=false
            state.listBrIncSel=action.payload
            state.error=''
        })
        builder.addCase(fetchBranchIncome.rejected, (state, action)=>{
            state.loading=false
            state.listBrIncSel=[]
            state.error=action.error.message || 'Something went wrong'
        })

        builder.addCase(fetchBranchIncomePrev.pending, (state)=>{
            state.loading=true
            state.error=''
            state.listBrIncPrev=[]
        })
        builder.addCase(fetchBranchIncomePrev.fulfilled, (state, action: PayloadAction<IDataBranchIncome[]>) => {
            state.loading=false
            state.listBrIncPrev=action.payload
            state.error=''
        })
        builder.addCase(fetchBranchIncomePrev.rejected, (state, action)=>{
            state.loading=false
            state.listBrIncPrev=[]
            state.error=action.error.message || 'Something went wrong'
        })

        builder.addCase(fetchCustomerStat.pending, (state)=>{
            state.loading=true
            state.error=''
            state.listCusStat=[]
        })
        builder.addCase(fetchCustomerStat.fulfilled, (state, action: PayloadAction<IDataCustomerStat[]>) => {
            state.loading=false
            state.listCusStat=action.payload
            state.error=''
        })
        builder.addCase(fetchCustomerStat.rejected, (state, action)=>{
            state.loading=false
            state.listCusStat=[]
            state.error=action.error.message || 'Something went wrong'
        })
    }
})


export const { setUserList, } = dataSlice.actions
export default dataSlice.reducer