import { Box, Hidden , Avatar, Typography,Tooltip} from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
const {REACT_APP_WEBAPP_VERSION,REACT_APP_LATEST_BUILD_VERSION,REACT_APP_LATEST_BUILD_TIMESTAMP} = process.env

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        color: #fff;
`
)

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
)

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(30)};
        font-weight: ${theme.typography.fontWeightBold};
        color:"#FFFFF";
`
)

function Logo() {
  const textToolTips = "Version " + REACT_APP_WEBAPP_VERSION + 
                    " (current build version " + REACT_APP_LATEST_BUILD_VERSION + 
                    ") latest build on " + REACT_APP_LATEST_BUILD_TIMESTAMP

  return (
    <LogoWrapper to="/overview">
      <Hidden smDown>
        {/* <Avatar src="/static/images/logo/baitullah.png" variant="square"/> */}
        <Avatar src="/static/images/logo/retali-white.png" variant="square"/>
          <LogoTextWrapper>          
            <LogoText>ZamZam</LogoText>
            <Tooltip title={textToolTips} arrow placement="right">
              <div>
                <Typography  fontSize={10}>
                  v{REACT_APP_WEBAPP_VERSION} (current build version {REACT_APP_LATEST_BUILD_VERSION})
                </Typography>
                {/* <Typography  fontSize={8}>
                  latest build on {REACT_APP_LATEST_BUILD_TIMESTAMP}
                </Typography> */}
              </div>
          </Tooltip>
        </LogoTextWrapper>
      </Hidden>
    </LogoWrapper>
  )
}

export default Logo